
<nav>
  <ul>
    <!-- Logo Section -->
    <li class="logo">
      <a routerLink="/">
        <img src="../assets/logo/logo.jpg" alt="Company Logo" width="50" height="50" />
      </a>
    </li>
    
    <!-- Navigation Links -->
    <li><a routerLink="/">Home</a></li>
    <li><a routerLink="/about">About</a></li>
    <li><a routerLink="/services">Services</a></li>
    <li><a routerLink="/contact">Contact</a></li>
    
    
  </ul>
  <div class="contact-info">
      <div>📞 044-26186019</div>
      <div>✉️ operations@bharathsecurityservice.com </div>
      <div>🌐  www.bharathsecurityservice.com</div>
     
     
  </div>
  
</nav>

<router-outlet></router-outlet>
