import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  scrollTo(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  cards = [
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 1' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 2' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 3' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 4' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 5' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 6' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 7' },
    { image: '../../assets/responsibility/firedrill.jpg', text: 'Card 8' },
  ];
  

}
