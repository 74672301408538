<!-- Footer Section -->
<footer class="footer bg-dark text-white">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h5>Contact Us</h5>
          <ul class="list-unstyled">
            <li><i class="fas fa-phone"></i> 044-26186019, 9884442982</li>
            <li><i class="fas fa-envelope"></i> www.bharathsecurityservice.com</li>
            <li><i class="fas fa-address"></i> Head Office:<br>
            44/131,Bharathi nager, 1st street, villivakam,chennai - 600049<br>
          </li>
          <li><i class="fas fa-address"></i> Corporate Office:<br>
            44/131,Bharathi nager, 1st street, villivakam,chennai - 600049<br>
          </li>

          </ul>
        </div>
        <div class="col-md-4">
          <h5>Follow Us</h5>
          <ul class="list-unstyled">
            <li><a href="" target="_blank" class="text-white"><i class="fab fa-facebook-square"></i> Facebook</a></li>
            <li><a href="" target="_blank" class="text-white"><i class="fab fa-whatsapp"></i> WhatsApp -</a>9884442982</li>
            <li><a href="" target="_blank" class="text-white"></a> 🌐 - operations@bharathsecurityservice.com</li>
          </ul>
        </div>
        <div class="col-md-4">
          <h5>More Info</h5>
          <ul class="list-unstyled">
            <li><a (click)="aboutus()" class="text-white">About Us</a></li>
            <li><a href="#terms" class="text-white">Terms of Service</a></li>
          </ul>
        </div>
      </div>
      <div class="text-center mt-3">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </div>
    </div>
  </footer>
  