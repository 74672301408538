<div class="home">
    <h1 class="heading">Welcome to BSS</h1>
    <h5 class="heading">Bharath Security services</h5>



      <!-- Carousel with Buttons and Captions -->
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../../assets/carousel/main_carousel-transformed.jpeg" class="d-block w-100" alt="...">
            <div class="carousel-caption">
              <h5>First Slide</h5>
              <button class="btn btn-primary" (click)="scrollTo('description1')">Learn More</button>
            </div>
          </div>
          <div class="carousel-item">
            <img src="../../assets/carousel/WhatsApp Image 2024-09-25 at 18.28.58_49c37769.png" class="d-block w-100" alt="...">
            <div class="carousel-caption">
              <h5>Fire Safety</h5>
              <button class="btn btn-primary" (click)="scrollTo('description2')">Learn More</button>
            </div>
          </div>
          <div class="carousel-item">
            <img src="../../assets/carousel/WhatsApp Image 2024-09-25 at 18.28.58_49c37769.png" class="d-block w-100" alt="...">
            <div class="carousel-caption">
              <h5>Third Slide</h5>
              <button class="btn btn-primary" (click)="scrollTo('description3')">Learn More</button>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <h3 class="heading">Our responsibility</h3>
      <div class="scroll-container " >
       
        <img clas="clients" src="../../assets/responsibility/firedrill.jpg" fetchpriority="high">
        <img clas="clients" src="../../assets/responsibility/night vist1.jpg"  fetchpriority="high">
        <img clas="clients" src="../../assets/responsibility/night vist2.jpg"  fetchpriority="high">
        <img clas="clients" src="../../assets/responsibility/physical checking.jpg"  fetchpriority="high">
        <img clas="clients" src="../../assets/responsibility/vechicle inspection 2.jpg"  fetchpriority="high">
        <img clas="clients" src="../../assets/responsibility/training 1.jpg"  fetchpriority="high">
        <img clas="clients" src="../../assets/responsibility/shift roll call.jpg"  fetchpriority="high">


      </div>

      
      

      <h3 class="heading">Our Major Clients</h3>
      <div class="scroll-containers">
        <div class="scroll-contents">
          <div class="cards"><img clas="clients" src="../../assets/clients/brakes india.png" fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/TVS-sundaram-clayton.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/BMQRep_Logos_Interior_Sundram.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/1539069537Sundaram Brake.jpg"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/Sundaram-Auto-APPL.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/toyota-tsusho-logo-9A91F32825-seeklogo.com.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/Tiic-Logo.png"  fetchpriority="high"></div>
         
          <!-- Duplicate the cards for seamless looping -->
          <div class="cards"><img clas="clients" src="../../assets/clients/brakes india.png" fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/TVS-sundaram-clayton.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/BMQRep_Logos_Interior_Sundram.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/1539069537Sundaram Brake.jpg"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/Sundaram-Auto-APPL.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/toyota-tsusho-logo-9A91F32825-seeklogo.com.png"  fetchpriority="high"></div>
          <div class="cards"><img clas="clients" src="../../assets/clients/Tiic-Logo.png"  fetchpriority="high"></div>
        </div>
      </div>
      <!-- <div class="scroll-container " >
       
        <img clas="clients" src="../../assets/clients/brakes india.png" fetchpriority="high">
        <img clas="clients" src="../../assets/clients/TVS-sundaram-clayton.png"  fetchpriority="high">
        <img clas="clients" src="../../assets/clients/BMQRep_Logos_Interior_Sundram.png"  fetchpriority="high">
        <img clas="clients" src="../../assets/clients/1539069537Sundaram Brake.jpg"  fetchpriority="high">
        <img clas="clients" src="../../assets/clients/Sundaram-Auto-APPL.png"  fetchpriority="high">
        <img clas="clients" src="../../assets/clients/toyota-tsusho-logo-9A91F32825-seeklogo.com.png"  fetchpriority="high">
        <img clas="clients" src="../../assets/clients/Tiic-Logo.png"  fetchpriority="high">


      </div> -->
    
    <!-- Description Sections for Scrolling -->
    <section id="description1">
        <h2>Shift Roll call at Work</h2>
        <p>What are low-res images and hi-res images?
            Low-res imges are images that are 72dpi and normally have an RGB colour setting. They are formatted for screen use (such as websites and social media). Hi-res images are images that are 300dpi and above. These are formatted for use in the print industry and are normally have a CMYK colour setting. The required dpi (dots per inch) for printing is higher than that for the computer screen, so even though the image looks OK on your monitor but will become blurred or pixelated when printed.
            Why use Photo Enhancer?
By using the best Enhancer to make blurry pictures clear, you can recover those special photos that you left abandoned in your hard drive. Even the most experienced photographers miss the focus or get some blurry and soft images sometimes.



What are low-res images and hi-res images?
Low-res imges are images that are 72dpi and normally have an RGB colour setting. They are formatted for screen use (such as websites and social media). Hi-res images are images that are 300dpi and above. These are formatted for use in the print industry and are normally have a CMYK colour setting. The required dpi (dots per inch) for printing is higher than that for the computer screen, so even though the image looks OK on your monitor but will become blurred or pixelated when printed.



Why my result file is so much larger in size?
Because we turned low-res image into an HD image so lots of information has been added to the original image. So the result can get bigger in size.



Does this Enhancer work on old family photos with scratches?
If the old photo needs to be denoised, you can use our Enhancer tool, but if it has scratches, you can use our Photo Retouch first to smear away the scratches.
            
            </p>
    </section>
    
    <section id="description2">
        <h2>Fire Safety Awareness program and first aid training at client location</h2>
        <p>This is the detailed description for the second image in the carousel.
        </p>
    </section>
    
    <section id="description3">
        <h2>Description for Image 3</h2>
        <p>This is the detailed description for the third image in the carousel.</p>
    </section>


    <div class="affiliation">
      <h3 class="heading">Our Affiliations</h3><br>

      <img src="https://static.wixstatic.com/media/fa203c_798aa4e7577e4fd984f587c60ef258dd~mv2.jpg/v1/fill/w_328,h_185,al_c,lg_1,q_80,enc_auto/fa203c_798aa4e7577e4fd984f587c60ef258dd~mv2.jpg" alt="AFFILIATED TO CAPSI" style="width:262px;height:148px;object-fit:cover" width="262" height="148" srcset="https://static.wixstatic.com/media/fa203c_798aa4e7577e4fd984f587c60ef258dd~mv2.jpg/v1/fill/w_328,h_185,al_c,lg_1,q_80,enc_auto/fa203c_798aa4e7577e4fd984f587c60ef258dd~mv2.jpg" fetchpriority="high">

    </div>

    
    
  
      
   
</div>
<app-footer></app-footer>
  