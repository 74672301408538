<div class="about">
    <h2>About Us</h2>
    <p>We wish to introduce ourselves as one of the biggest and leading with Prominent Security Guard services organisation in Chennai since 1982 Founded by chandrasekeran with the advise of Retd Indian army officer Kanagarathinam. Presently Managed by eSUNDARAMURTHY (MANAGING DIRECTOR). We have pioneered the concept of Security System with BSS team.Highly trained special security guards are in this group. Advance training have been given in the field of First Aid, Vigilance, Fire Fighting and also maintaining the necessary records etc to all security guards. Our strength is our dedicated security force services to the chennai. We provide our Cadres the benefit of P. F. & E.S.I. We cater all kinds of industries where security guards service is essential. To name a few Government Organisation like TIIC- Tamil nadu Industrial Investment Corporation, and Corporate Clients like Tvs credit Ltd, Sundaram clayton, Lakshmi Vilas Bank and Govt temples. We have branches in tamil nadu Chennai and kanceepuram. We are also planning to have branches all over tamil nadu very soon. Kindly find our list of all over south india clients and other details enclosed.<br> ■Security guards will enhance your front gate image, with their smart uniform, alertness & help in establishing good customer relations.<br> ■Help in prevention of theft / damage to property & will function as a profit centre instead of cost centre by our security force.<br> ■Security Employee discipline will improve, thus improve productivity. ■Quality and cost effective security services. ■ESI, PF and other statutory requirements will be taken care of by black panther security guard service. ■security guards will guard your company in at most care. ■security force are will trained to manage in any circumstance.</p>
    <br>
    <h2>Board of directors</h2>
  </div>

  <div class="image-grid-container">

    <div class="image-cell"><img src="../../assets/board of directors/tom_be2af94.png" alt="Image 1"></div>
    <div class="image-cell"><img src="../../assets/board of directors/tom_be2af94.png" alt="Image 2"></div>
    <div class="image-cell"><img src="../../assets/board of directors/tom_be2af94.png" alt="Image 3"></div>
    <div class="image-cell"><img src="../../assets/board of directors/tom_be2af94.png" alt="Image 4"></div>
  </div>
  

  <app-footer></app-footer>