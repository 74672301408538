<div class="contact-section">
  <div class="contact">
    <h2>Contact Us</h2>
    <form>
      <label for="name">Name:</label>
      <input type="text" id="name" name="name" />
  
      <label for="email">Email:</label>
      <input type="email" id="email" name="email" />
  
      <label for="message">Message:</label>
      <textarea id="message" name="message"></textarea>
  
      <button type="submit">Submit</button>
    </form>
  </div>
  <div class="map-location"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.841269308339!2d80.20357807454866!3d13.10924021184677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52650006cc4b89%3A0x247db0cb1162ddef!2sBHARATH%20SECURITY%20SERVICES!5e0!3m2!1sen!2sin!4v1727699373162!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>

</div>

<app-footer></app-footer>
